import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import Icon from '../Icon';
import Paragraph from '../Paragraph';
import Heading from '../Heading';

import { alertStyleDanger, alertStyleSuccess } from './simplealert.style';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  box-sizing: border-box;
  align-items: center;
  @media screen and (max-width: 640px) {
    padding: 0.5rem;
  }
`;

const StyledIcon = styled(Icon)`
  /* flex: none; */
  @media screen and (max-width: 640px) {
    width: 32px;
  }
`;

const Text = styled.div`
  margin-left: 1rem;
  overflow: auto;
  > :first-child {
    margin: 0;
  }
`;

const AlertHeading = styled(Heading)`
  ${ifProp(
    { variant: 'danger' },
    alertStyleDanger,
    ifProp({ variant: 'success' }, alertStyleSuccess, '#282e38')
  )};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const AlertParagraph = styled(Paragraph)`
  ${ifProp(
    { variant: 'danger' },
    alertStyleDanger,
    ifProp({ variant: 'success' }, alertStyleSuccess, '#282e38')
  )};
  opacity: 1;
  margin-top: 4px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0.5rem
`;

const SimpleAlert = ({ icon, title, children, variant, ...props }) => {
  return (
    <Wrapper {...props}>
      {icon && <StyledIcon icon={icon} height={24} />}
      <Text>
        <AlertHeading level={3} variant={variant}>
          {title}
        </AlertHeading>
        <AlertParagraph variant={variant}>{children}</AlertParagraph>
      </Text>
    </Wrapper>
  );
};

SimpleAlert.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.any,
  variant: PropTypes.string,
};

export default SimpleAlert;
