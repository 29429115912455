import PageTemplate from 'templates/PageTemplate';
import Header from 'components/Header';

const Dashboard = () => {
  return (
    <PageTemplate header={<Header />} />
  )
};

export default Dashboard;
