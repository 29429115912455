import { css } from 'styled-components';

const fontSize = ({ level }) => `${0.75 + (1 * (1 / level))}rem`

export const headingStyle = css`
  font-weight: 500;
  font-size: ${fontSize};
  margin: 0;
  margin-top: 0.8em;
  margin-bottom: 0.5em;
  color: #282E38;
`;
