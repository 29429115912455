import React from 'react';
import LogoImage from '../LogoImage';
import Heading from '../Heading';

export const Header = (props) => {
  return (
    <>
      <LogoImage />
      <Heading level={1}>Tisk dokumentů</Heading>
    </>
  );
};

export default Header;
