import { Switch, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import PrintPage from 'pages/PrintPage';
import Dashboard from 'pages/Dashboard';

/** CSS Import */
import './App.css';

/** Create react-query client */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  // let { path } = useRouteMatch();
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route path="/poslat/:pairingID">
          <PrintPage />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
