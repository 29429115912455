import React from 'react';
import styled from 'styled-components';

import { StyledHr } from './divider.style.js';

const StyledSpan = styled.span`
  letter-spacing: 0.1em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 16px;
  font-weight: 500;
  text-transform: uppercase;
  &::before {
    display: inline-block;
    content: '';
    height: 1px;
    width: 100%;
    background-color: #d4d4d4;
    margin: 0 16px 0 0;
    transform: translateY(-2px);
  }
  &::after {
    display: inline-block;
    content: '';
    height: 1px;
    width: 100%;
    background-color: #d4d4d4;
    margin: 0 0 0 16px;
    transform: translateY(-2px);
  }
`;

export const DividerText = ({ children }) => (
  <StyledSpan>{children}</StyledSpan>
);

const Divider = (props) => <StyledHr />;

export default Divider;
