import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { format } from 'date-fns';
import env from "@beam-australia/react-env";
import { useParams } from 'react-router-dom';

import PageTemplate from 'templates/PageTemplate';
import Header from 'components/Header';
import Paragraph from 'components/Paragraph';
import Strong from 'components/Strong';
import List from 'components/List';
import Divider from 'components/Divider';
import SimpleAlert from 'components/SimpleAlert';
import IconButton from 'components/IconButton';
import PrintExpired from 'components/PrintExpired';
import AlreadyPrinted from 'components/AlreadyPrinted';
import NotFound404 from 'components/NotFound404';
import Checkbox from 'components/Checkbox';

import chevronIcon from 'components/Icon/icons/chevron.png';
import dangerIcon from 'components/Icon/icons/danger.png';
import successIcon from 'components/Icon/icons/success.png';
import errorIcon from 'components/Icon/icons/error.svg';

const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0.5em 0;
`;

const Address = styled.div`
  color: #282e38;
  opacity: 0.5;
  padding: 16px 0;
  font-size: 14px;
  & div {
    padding: 2px 0;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 20px;
`;

const StyledLink = styled.a`
  font-weight: 700;
  color: #e9573d;
`;

const API_URL = env("API_URL");

const AXIOS_CONFIG = {
  headers: {
    Accept: 'application/json',
  },
};

const fetchDocuments = async (pairingID, setCheckedState) => {
  const { data } = await axios.get(
    `${API_URL}/printing/${pairingID}`,
    AXIOS_CONFIG
  );
  console.log('API Called');
  if (data.documents){
    setCheckedState(data.documents.map(doc => {
      return { id: doc.documentID, checked: doc.forcedCheckbox };
    }));
  }
  return data;
};

const PrintPage = (props) => {
  const [checkedState, setCheckedState] = useState([]);

  const { pairingID } = useParams();

  const { isLoading, isError, data } = useQuery('printingDocs', () =>
    fetchDocuments(pairingID, setCheckedState)
  );

  const mutation = useMutation(({ pairingID, data }) => {
    return axios.post(`${API_URL}/printing/${pairingID}`, checkedState.filter(x => x.checked).map(x => x.id));
  });

  const handleSentPrint = useCallback(() => {
    mutation.mutate({ pairingID });
  }, [mutation, pairingID]);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    debugger;
    return <NotFound404 />;
  }

  if (data?.alreadyPrinted) {
    return <AlreadyPrinted {...data} />;
  }

  if (data?.expired) {
    return <PrintExpired />;
  }

  const WrapPolicyNo = (input) => {
    if (input.includes("č ")) {
      const firstSplitted = input.split("č ")[0];
      return (
        <Strong weight={700} fontSize="14px">
          {firstSplitted}
          <span style={{ whiteSpace: 'nowrap' }}>
            {input.substring(firstSplitted.length)}
          </span>
        </Strong>
      );
    }
    else {
      return (
        <Strong weight={700} fontSize="14px">
          {input}
        </Strong>
      );
    }
  }

  const { documents, address, expirationDate } = data;

  const handleOnChange = (index) => {
    setCheckedState(previousState => {
      let items = previousState.slice();
      let item = { ...items[index] };
      item.checked = !previousState[index].checked;
      items[index] = item;
      return items;
    });

  };

  return (
    <PageTemplate header={<Header />}>
      <Paragraph>
        Vážený kliente, chcete požádat o vytištění a zaslání dokumentů poštou.
        Dokumenty Vám pošleme na níže uvedenou adresu po potvrzení tlačítka{' '}
        <Strong weight={700}>Tisknout a odeslat</Strong>. Adresní údaje jsou z
        důvodu ochrany osobních údajů záměrně neúplné.
      </Paragraph>
      <Paragraph>
        O tisk a zaslání dokumentů můžete přes tuto aplikaci požádat jen jednou.
        Pokud je budete v budoucnu potřebovat zaslat znovu, zavolejte nám na 241
        114 114 nebo navštivte kteroukoli pobočku GČP.
      </Paragraph>

      <Strong weight={700} fontSize="14px">
        Dokumenty určené k tisku
      </Strong>

      <List>
        {documents.map((document, index) => (
            <ListItem key={index}>
              <Checkbox
                id={index}
                disabled={document.forcedCheckbox || mutation?.isSuccess || mutation?.isError}
                checked={checkedState[index].checked}
                onChange={() => handleOnChange(index)}
                label={WrapPolicyNo(document?.documentName)}
              />
            </ListItem>
        ))}
      </List>

      <Strong weight={700} fontSize="14px">
        Doručovací adresa
      </Strong>
      <Address>
        <div>
          <span>{address?.businessName}</span>
        </div>
        <div>
          <span>{address?.clientName}</span>{' '}
          <span>{address?.clientSurname}</span>
        </div>
        <div>
          <span>{address?.street}</span> <span>{address?.houseNo}</span>
        </div>
        <div>
          <span>{address?.city}</span>
        </div>
        <div>
          <span>{address?.zipCode}</span>
        </div>
        <div>
          <span>{address?.country}</span>
        </div>
      </Address>
      <Divider />

      <SimpleAlert title="POZOR!" icon={dangerIcon} variant="danger">
        O vytištění a zaslání dokumentů můžete požádat nejpozději do{' '}
        <span>{format(new Date(expirationDate), 'd. M. yyyy')}</span>. Po tomto
        datu již budou nedostupné.
      </SimpleAlert>
      <Divider />
      {!checkedState.some(x => x.checked) && (
        <SimpleAlert>
          Pokud chcete požádat o tisk a odeslání dokumentu, zaškrtněte prosím požadovaný dokument.
        </SimpleAlert>
      )}
      {mutation?.isSuccess && (
        <SimpleAlert icon={successIcon} variant="success">
          Váš požadavek na tisk a odeslání dokumentů poštou jsme přijali.
          Doručení dokumentů poštou může trvat až 10 dní.
        </SimpleAlert>
      )}

      {mutation?.isError && (
        <SimpleAlert icon={errorIcon} variant="danger">
          Omlouváme se, z důvodu technické chyby teď nemůžeme vyřídit Váš
          požadavek na tisk a odeslání dokumentů poštou. Prosím, zavolejte nám
          na 241 114 114 nebo{' '}
          <StyledLink href="https://www.generaliceska.cz/napiste-nam">
            nám napište
          </StyledLink>.
        </SimpleAlert>
      )}
      {(mutation?.isSuccess || mutation?.isError) ?
        null :
        (
          <ButtonWrapper>
            <IconButton onClick={handleSentPrint} icon={chevronIcon} right disabled={!checkedState.some(x => x.checked)}>
              Tisknout a odeslat
            </IconButton>
          </ButtonWrapper>
        )
      }
    </PageTemplate>
  );
};

export default PrintPage;
