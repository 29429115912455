import { css } from 'styled-components';
import { ifProp } from 'styled-tools';

// const fontSize = ({ height }) => `${height / 40}rem`;
const fontSize = '1rem';

const backgroundColor = (props) => props.disabled ? '#0000001f' : '#c21b17';

const foregroundColor = ({ transparent, disabled }) => '#ffffff';

const hoverBackgroundColor = (props) => props.disabled ? '#0000001f' : '#e9573d';

const hoverForegroundColor = ({ disabled, transparent }) => '#ffffff';

export const buttonStyles = css`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: ${fontSize};
  font-weight: 700;
  border: 1px solid #c21b17;
  height: 3em;
  text-decoration: none;
  ${!ifProp('disabled') && 'cursor: pointer'};
  appearance: none;
  padding: 16px 16px 16px 22px;
  border-radius: 0;
  box-sizing: border-box;
  border-color: ${backgroundColor};
  background-color: ${backgroundColor};
  color: ${foregroundColor};
  width: 100%;
  /* text-align: left; */

  &:hover,
  &:active {
    background-color: ${hoverBackgroundColor};
    border-color: ${hoverBackgroundColor};
    color: ${hoverForegroundColor};
  }

  &:focus {
    outline: none;
  }
`;
