import React, { Component } from 'react';
import styled from 'styled-components';

class Checkbox extends Component {
  render() {
    return (
      <Div disabled={this.props.disabled}>
        <label>
          <span>
            <CheckboxControl
              type="checkbox"
              checked={this.props.checked}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
            />
          </span>
          <span style={{paddingLeft: '6px'}}>{this.props.label}</span>
        </label>
      </Div>
    );
  }
}

export default Checkbox;

const Div = styled.div`
> * {
  ${({ disabled }) => !disabled && `
cursor: pointer;
`}
}
`

const CheckboxControl = styled.input.attrs({ type: "checkbox" })`
width: 14px;
height: 14px;
accent-color: #f14a3f;

${({ disabled }) => !disabled && `
cursor: pointer;
`}
`
