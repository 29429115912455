import styled, { css } from 'styled-components';

export const dividerStyle = css`
  border: 1px solid #d4d4d4;
  border-width: 0 0 1px;
  width: 100%;
`;

export const StyledHr = styled.hr`
  ${dividerStyle}
`;
