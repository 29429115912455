import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { listStyles } from './list.style.js';

const Ol = styled.ol`
  ${listStyles}
`;

const Ul = styled.ul`
  ${listStyles}
`;

const List = ({ ordered, children, ...props }) => {
  return React.createElement(ordered ? Ol : Ul, props, children);
};

List.propTypes = {
  ordered: PropTypes.bool,
  children: PropTypes.any,
};

List.defaultProps = {
  ordered: false,
};

export default List;
