import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { headingStyle } from './heading.style.js';

const Heading = styled(({ level, children, ...props }) =>
  React.createElement(`h${level}`, props, children)
)`
  ${headingStyle}
`;

Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node,
};

Heading.defaultProps = {
  level: 1,
};

export default Heading;
