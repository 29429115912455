import React from 'react';
import styled from 'styled-components';

import PageTemplate from 'templates/PageTemplate';
import Header from 'components/Header';

import './printexpired.style.js';

const StyledParagraph = styled.p`
  color: #282e38;
  font-size: 14px;
  line-height: 1.5;
  margin: 1rem 0;
  text-align: left;
`;

const StyledText = styled.span`
  opacity: 50%;
`;

const StyledLink = styled.a`
  font-weight: 700;
  color: #e9573d;
  opacity: 1;
`;

export const PrintExpired = (props) => {
  return (
    <PageTemplate header={<Header />}>
      <StyledParagraph>
        <StyledText>
          Vážený kliente, vypršel termín, do kdy jste mohl o zaslání dokumentů
          požádat. Pokud dokumenty stále potřebujete, zavolejte nám na 241 114
          114, navštivte kteroukoli pobočku GČP nebo{' '}
        </StyledText>
        <StyledLink href="https://www.generaliceska.cz/napiste-nam">
          nám napište
        </StyledLink>
        <StyledText>
          .
        </StyledText>
      </StyledParagraph>
    </PageTemplate>
  );
};

export default PrintExpired;
