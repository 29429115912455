import styled from 'styled-components';

export const StyledParagraph = styled.p`
  color: #282e38;
  font-size: 14px;
  opacity: 50%;
  line-height: 1.5;
  margin: 1rem 0;
  text-align: left;
`;
