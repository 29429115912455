import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import PageTemplate from 'templates/PageTemplate';
import Header from 'components/Header';

import './alreadyprinted.style.js';

const StyledParagraph = styled.p`
  color: #282e38;
  font-size: 14px;
  line-height: 1.5;
  margin: 1rem 0;
  text-align: left;
`;

const StyledText = styled.span`
  opacity: 50%;
`;

const StyledLink = styled.a`
  font-weight: 700;
  color: #e9573d;
  opacity: 1;
`;

export const AlreadyPrinted = (props) => {
  return (
    <PageTemplate header={<Header />}>
      <StyledParagraph>
        <StyledText>
          Vážený kliente, o dokumenty jste již požádal dne{' '}
          {format(new Date(props?.printedDate), 'd. M. yyyy')} a Vaši žádost
          jsme vyřídili. Pokud jste je neobdržel, zavolejte nám na 241 114 114,
          navštivte kteroukoli pobočku GČP nebo{' '}
        </StyledText>
        <StyledLink href="https://www.generaliceska.cz/napiste-nam">
          nám napište
        </StyledLink>
        <StyledText>
          .
        </StyledText>
      </StyledParagraph>
    </PageTemplate>
  );
};

export default AlreadyPrinted;
